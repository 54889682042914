import { getNativeLanguagesForLearningLanguage } from 'src/config/i18n'
import { LanguageCode, SiteLocale } from 'src/types'

const WEB_APP_URL = process.env.WEB_APP_URL || 'https://app.chatterbug.com'

export const getLessonsSignupUrl = (
  locale: SiteLocale,
  l2Code?: LanguageCode | null
): string => {
  const queryParams = [`locale=${locale}`]
  let url = `${WEB_APP_URL}/signup/`
  let hasL1Pair = false

  if (l2Code) {
    const nativeLanguages: string[] = getNativeLanguagesForLearningLanguage(
      l2Code
    )
    hasL1Pair = nativeLanguages.includes(locale)

    url += hasL1Pair ? `${l2Code}-${locale}` : `${l2Code}`
  }

  if (!hasL1Pair) {
    queryParams.push('force_missing_l1')
  }

  return `${url}?${queryParams.join('&')}`
}

export const getStreamsGiftVoucherParams = (code: string): string => {
  return `giftVoucherCode=${code}`
}

export const getLessonsGiftVoucherParams = (code: string): string => {
  return `gift_voucher_code=${code}`
}

export const getStreamsSignupUrl = (locale: SiteLocale): string =>
  `/${locale}/streams-subscription/signup`

export const getStreamsLoginUrl = (locale: SiteLocale): string =>
  `/${locale}/streams-subscription/login`

export const getLessonsLoginUrl = (): string => `${WEB_APP_URL}/login`

export const getMailTo = (subject?: string): string =>
  `mailto:hello@chatterbug.com${subject ? `?subject=${subject}` : ''}`

export const getFaqUrl = (locale: SiteLocale) =>
  `https://chatterbug.com/community/c/support/34`

export const getStreamsAppUrls = (locale: SiteLocale) => {
  if (locale === SiteLocale.de) {
    return {
      ios: {
        mobile: 'https://app.adjust.com/psdf69i',
        desktop: 'https://app.adjust.com/67pok1d',
      },
      android: {
        mobile: 'https://app.adjust.com/yoa5ydg',
        desktop:
          'https://play.google.com/store/apps/details?id=com.chatterbug.chatterstreams&referrer=adjust_reftag%3DcWjkT7ZTXts27%26utm_source%3DDE%2BLanding%2BPage%26utm_campaign%3DDE%2BGoogle%2BPlay%2BDesktop%2BLP',
      },
    }
  }

  // en
  return {
    ios: {
      mobile: 'https://app.adjust.com/7r0sl0x',
      desktop: 'https://app.adjust.com/9lw84b1',
    },
    android: {
      mobile: 'https://app.adjust.com/cvciv2o',
      desktop:
        'https://play.google.com/store/apps/details?id=com.chatterbug.chatterstreams&referrer=adjust_reftag%3DcabH2Hp9OSSo0%26utm_source%3DLanding%2BPage%2B%2528Web%2BBadges%2529%26utm_campaign%3DAndroid%2BGoogle%2BPlay%2BDesktop',
    },
  }
}

export const getStreamsAppUrlsWithoutTracking = () => {
  return {
    ios: 'https://apps.apple.com/app/id1549226733',
    android:
      'https://play.google.com/store/apps/details?id=com.chatterbug.chatterstreams',
  }
}

export const isMobileUserAgent = () =>
  navigator?.userAgent &&
  /Android|iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent) &&
  'ontouchend' in document

export const CB_LINKS = {
  redeemVoucher: '/vouchers/redeem',
  vouchers: '/lessons/vouchers',
  blog: 'https://blog.chatterbug.com',
  community: 'https://chatterbug.com/community',
  business: '/business',
  chatterconf: '/en/chatterconf',
  about: '/en/about',
  careers: 'https://chatterbug.breezy.hr',
  press: '/en/press',
  contact: '/en/contact',
  chatterbugGo: 'https://go.chatterbug.com',
  chatterbugLive: '/en/chatterbug-live',
  chatterbugLiveYoutube:
    'https://www.youtube.com/playlist?list=PLEzOGOOV27q0MkpSIpuPDGRr7P2CPJJTW',
  becomeTutor: 'https://chatterbug.com/en/become-a-tutor',
  security: '/en/security',
  status: 'http://status.chatterbug.com',
  onlineSchool: '/en/online-language-school',
  amty: 'https://amty.io',
  privacy: '/en/legal/privacy',
  terms: '/en/legal/terms',
  social: {
    facebook: 'https://facebook.com/chatterbugapp',
    twitter: 'https://twitter.com/chatterbugapp',
    instagram: 'https://instagram.com/chatterbug.app',
    youtube: 'https://www.youtube.com/c/chatterbug',
    github: 'https://github.com/chatterbugapp',
  },
  googleReview:
    'https://www.google.com/search?q=chatterbug#lrd=0x47a84f612a362e6f:0x44d82ce657888eea,1',
  podcasts: {
    de: 'https://www.buzzsprout.com/1089272',
    en: 'https://www.buzzsprout.com/1789743',
    fr: 'https://www.buzzsprout.com/1344619',
  },
  bildungsurlaub: '/bildungsurlaub',
}
